<template lang="pug">
.min-h-screen.overflow-x-hidden
  heroSection
  sectionBranding
  sectionInformation
  sectionCTA
  sectionComments
  sectionPrices(v-if="showPicesComponent")
  sectionAnalitycs
</template>
<script>
import { defineAsyncComponent } from "vue";

const heroSection = defineAsyncComponent(() =>
  import("@/components/www/home/hero")
);

const sectionBranding = defineAsyncComponent(() =>
  import("@/components/www/home/sectionBranding")
);
const sectionInformation = defineAsyncComponent(() =>
  import("@/components/www/home/sectionInformation")
);
const sectionCTA = defineAsyncComponent(() =>
  import("@/components/www/home/sectionCTA")
);
const sectionComments = defineAsyncComponent(() =>
  import("@/components/www/home/sectionComments")
);
const sectionAnalitycs = defineAsyncComponent(() =>
  import("@/components/www/home/sectionAnalitycs")
);

const sectionPrices = defineAsyncComponent(() =>
  import("@/components/www/prices/prices-component.vue")
);

export default {
  name: "Home",
  data: () => ({
    showPicesComponent: false,
  }),
  components: {
    heroSection,
    sectionBranding,
    sectionInformation,
    sectionCTA,
    sectionComments,
    sectionAnalitycs,
    sectionPrices,
  },
  created() {
    this.showPicesComponent =
      this.$store.getters["ProductsStore/getproductDefault"].product_type ==
      "subscription";
  },
};
</script>
<style lang="scss" scoped>
</style>
